import getScriptMetadata from "../utils/getScriptMetadata";
import Surface from "./Surface";

class Overlay extends Surface {
	constructor({ config, orchestrator, messageCallbacks = {}, ...rest }) {
		const allMessageCallbacks = {
			OPEN_OVERLAY_ORCHESTRATOR: () =>
				config.lockBodyScroll(this.globalState),
			CLOSE_OVERLAY_ORCHESTRATOR: () => {
				config.unlockBodyScroll(this.globalState);
				this.close();
				orchestrator.surfaces.widget.startTrack();
			},
			...messageCallbacks,
		};
		super({
			idx: "coop-overlay",
			src: config.makeUrl("overlay"),
			config,
			mode: Surface.MODES.FULLSCREEN,
			htmlId: "coopcommerce-overlay",
			orchestrator,
			messageCallbacks: allMessageCallbacks,
			hideInitially: config.getPublisherProperty("hideInitially") || true,
			...rest,
		});
	}
	setMountAfter() {
		this.mountAfter = null;
	}
	show(remoteData) {
		const { display_order: displayOrder } = remoteData;
		if (!Array.isArray(displayOrder) || displayOrder.length === 0) {
			this.unMount();
			this.orchestrator.surfaces.widget.startTrack();
			return;
		}
		this.message("LOAD", {
			...remoteData,
			global_event_metadata: {
				...(remoteData?.global_event_metadata || {}),
				...(this?.globalState?.globalEventMetadata || {}),
			},
			publisher: this.config.PUBLISHER,
			parentInnerHeight: window.innerHeight,
			parentInnerWidth: window.innerWidth,
			view: "OVERLAY",
			initialMode: Surface.MODES.FULLSCREEN,
			scrapedInformation: this.orchestrator.getApiPayload(),
			coopSession: this.globalState.coopSession,
			runningExperiments: this.globalState.runningExperiments,
			scriptMetadata: getScriptMetadata({
				config: this.config,
				globalState: this.globalState,
			}),
		});

		this.setDims();
		this.startTrack();
	}
	getApiRequest(payload) {
		return fetch(
			`${this.config.ACTIVE_ENV.apiUrl}core/modules/${this.config.PUBLISHER}/`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			}
		);
	}
}

export default Overlay;
