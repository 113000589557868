const debounce = (fn, delta, thisContext = null) => {
	let timer = false;
	return (...args) => {
		if (timer !== false) {
			clearTimeout(timer);
		}

		timer = setTimeout(() => {
			fn.apply(thisContext, args);
			timer = false;
		}, delta);
	};
};
export default debounce;
