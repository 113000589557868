const getScriptMetadata = ({ config = {}, globalState = {} } = {}) => {
	let metadata = {};

	if (Object.entries(globalState).length) {
		metadata = {
			...metadata,
			shop_theme: globalState.shopTheme,
			active_widget_type: globalState.activeWidgetType,
			active_widget_version: globalState.activeWidgetVersion,
		};
	}

	return metadata;
};
export default getScriptMetadata;
