import addUrlParam from "../utils/addUrlParam";
import applyStyles from "../utils/applyStyles";
import { GETScript } from "../utils/GET";
import Frame from "./Frame";

class Tracker extends Frame {
	queue = [];
	snowplowQueue = [];

	static HIDDEN_STYLES = {
		height: "0px",
		width: "0px",
		opacity: "0",
		pointerEvents: "none",
	};

	trackable = false;

	snowplowTrackable = false;

	constructor({ config, messageCallbacks, signalGlobalState, ...rest }) {
		let src = addUrlParam(
			config.ACTIVE_ENV.trackerUrl,
			"environment",
			config.ACTIVE_ENV.env
		);
		src = addUrlParam(src, "surfaceIdx", "disco-tracker-internal");
		src = addUrlParam(src, "host", document.URL);

		super({
			idx: "disco-tracker-internal",
			htmlId: "disco-tracker-internal",
			src,
			messageCallbacks: {
				...messageCallbacks,
				CONFIGURE_INTERNAL_USER: ({ payload = {} } = {}) => {
					if ("coop_user_id" in payload) {
						this.globalState.user.id =
							payload?.coop_user_id || null;
					}
					if ("disco_uuid" in payload) {
						this.globalState.user.discoUuid =
							payload?.disco_uuid || null;
					}

					if (typeof signalGlobalState !== "function") return;
					signalGlobalState();
				},
				TRACKABLE: () => {
					console.log(
						"%c Tracker live!",
						"background-color: #2ecc91; color: white; font-weight: 600; font-size: 15px;"
					);
					this.trackable = true;
					this.queue.forEach((event) => this.trackMessage(event));
					this.queue = [];
				},

				SNOWPLOW_TRACKABLE: () => {
					console.log(
						"%c snowplow Tracker live!",
						"background-color: #2ecc91; color: white; font-weight: 600; font-size: 15px;"
					);
					this.snowplowTrackable = true;
					this.snowplowQueue.forEach((event) =>
						this.snowplowTrackMessage(event)
					);
					this.snowplowQueue = [];
				},
			},
			config,
			...rest,
		});
	}

	show(remoteData) {
		this.message("LOAD", {
			...remoteData,
			userAgent: window?.navigator?.userAgent || null,
			publisher: this.config.PUBLISHER,
			scrapedInformation: this.orchestrator.getApiPayload(),
			identifyUrl: this.config.ACTIVE_ENV.identifyUrl,
			disableTracking: this.config.disableTracking,
		});
	}

	setMountAfter() {
		this.mountAfter = null;
	}

	setDims() {
		applyStyles(this.iFrame, Tracker.HIDDEN_STYLES);
	}

	prepareMount() {
		super.prepareMount();
		this.setDims();
	}

	snowplowTrackMessage({ type, event }) {
		this.message("SNOWPLOW_TRACK", { type, event });
	}
	trackMessage({ name, properties }) {
		this.message("TRACK", { name, properties });
	}

	identifyRemoteUser({ payload }) {
		this.message("IDENTIFY_REMOTE_USER", payload);
	}

	track(name, properties) {
		const allProperties = {
			userAgent: window?.navigator?.userAgent || null,
			...(properties || {}),
		};
		if (this.trackable)
			this.trackMessage({ name, properties: allProperties });
		else this.queue.push({ name, properties: allProperties });
	}

	snowplowTrack(data) {
		if (this.snowplowTrackable) this.snowplowTrackMessage(data);
		else this.snowplowQueue.push(data);
	}
}

export default Tracker;
