// isDeviceMobile => checks if the size is device is actually mobile (<600) or just the page simulating mobile conditions (<1000)
// Legacy Comment for using DiscoFeed 4.0 with isMob()
// Experiment 4.0 shows desktop widget if width > 600
// where as 2.0 shows desktop widget if width > 1000
const isMob = ({ isDeviceMobile = false } = {}) => {
	if (isDeviceMobile) {
		return window.innerWidth < 600;
	}
	return window.innerWidth < 1000;
};
export default isMob;
