import log from "../utils/log";
import uuid4 from "../utils/uuid4";

class Frame {
	static WAIT_BEFORE_UNMOUNT = 32000;

	// IMPORTANT: States are ordinal, ordered by time of occurrence
	static states = {
		UNMOUNTED: 1,
		MOUNT_PREPARED: 2,
		MOUNTED: 2,
		AVAILABLE: 3,
	};

	config = {};
	globalState = {};
	orchestrator = {};

	availableCallbacks = [];

	idx = null;
	src = null;
	iFrame = null;
	mountAfter = null;
	unMountTimer = null;
	state = Frame.states.UNMOUNTED;

	messageCallbacks = {};

	constructor({
		// Required!
		config,
		globalState,
		orchestrator,

		idx = "",
		src = "",
		messageCallbacks = {},
		htmlId = false,
	}) {
		this.config = config;
		this.orchestrator = orchestrator;
		this.globalState = globalState;

		this.idx = idx;
		this.src = src;
		this.htmlId = htmlId || `disco-${uuid4()}`;

		this.messageCallbacks = {
			...messageCallbacks,
		};
	}

	addAvailableListener(callback) {
		if (typeof callback !== "function") return;
		if (this.state === Frame.states.AVAILABLE) callback(false);

		this.availableCallbacks.push(callback);
	}

	handleAvailable() {
		clearTimeout(this.unMountTimer);

		console.log(`Frame available: ${this.idx}. Running start-up tasks!`);

		const reMount = this.state === Frame.states.AVAILABLE;

		if (reMount) {
			this.iFrame = document.querySelector(`#${this.htmlId}`);
			this.setDims();
		}

		this.state = Frame.states.AVAILABLE;
		this.availableCallbacks.forEach((callback) => callback(reMount));
	}

	handleMessage({ data }) {
		const { type, payload, src } = data;
		log("[ MESSAGE RECEIVED ]", src, "=>", type);
		if (src !== this.idx) {
			return;
		}

		if (type === "AVAILABLE") {
			this.handleAvailable();
			return;
		}
		if (!(type in this.messageCallbacks)) {
			return;
		}
		if (typeof this.messageCallbacks[type] !== "function") {
			return;
		}

		this.messageCallbacks[type]({ type, payload });
	}

	addMessageListener({ type, callback }) {
		this.messageCallbacks[type] = callback;
	}

	message(type, payload) {
		if (!this.iFrame?.contentWindow) return;
		this.iFrame.contentWindow.postMessage({ type, payload }, "*");
	}

	attachEvents() {
		this.iFrame.onload = this.mount.bind(this);
		window.addEventListener("message", this.handleMessage.bind(this));
	}

	mount() {
		if (this.state < Frame.states.MOUNTED) {
			this.state = Frame.states.MOUNTED;
		}
	}

	unMount() {
		console.log(`Unmounting frame ${this.idx}`);
		this.open = false;
		this.state = Frame.states.UNMOUNTED;
		if (this.iFrame) {
			this.iFrame.remove();
		}
	}

	setMountAfter() {
		console.error(`Unimplemented mount after`);
	}

	launch() {
		this.setMountAfter();
		this.prepareMount();
	}

	prepareMount(forceMount = true) {
		this.state = Frame.states.MOUNT_PREPARED;

		this.iFrame = document.createElement("iframe");

		this.iFrame.className = this.idx;
		this.iFrame.title = this.idx;

		this.iFrame.id = this.htmlId;

		this.iFrame.src = this.src;

		this.iFrame.allow = "clipboard-read; clipboard-write";

		// this.configProperties();

		if (!this.mountAfter && !forceMount) return;

		if (!this.mountAfter) {
			document.body.appendChild(this.iFrame);
		} else {
			this.mountAfter.parentNode.insertBefore(
				this.iFrame,
				this.mountAfter.nextSibling
			);
		}

		this.attachEvents();
		this.state = Frame.states.MOUNT_PREPARED;
		this.unMountTimer = setTimeout(() => {
			this.unMount();
		}, Frame.WAIT_BEFORE_UNMOUNT);
	}
}

export default Frame;
