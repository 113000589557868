import applyStyles from "../utils/applyStyles";

class Footer {
	el = null;
	text = null;
	cta = null;
	emailInput = null;
	rewardsDisabled = false;
	optedIn = false;
	logo = null;
	state = null;
	emailOpen = false;
	emailSent = false;
	_onWidget = null;

	constructor({ config, orchestrator, widget, globalState }) {
		this.config = config;
		this.widget = widget;
		this.orchestrator = orchestrator;
		this.globalState = globalState;
	}

	makeConsumerRedirectUrl() {
		const url = this.config.makeConsumerRedirectUrl({
			globalState: this.globalState,
		});
		return url;
	}

	setOutText(text, bigButton) {
		this.states.out.textHTML = text;
		this.states.out.ctaHTML = bigButton ? "See your list ↓" : "↓";
	}

	disableRewards() {
		this.rewardsDisabled = true;
		this.updateState();
	}

	optIn() {
		this.optedIn = true;
		this.updateState();
	}

	setPoints(points) {
		if (isNaN(points)) {
			return;
		}
		this.states.in.textHTML = `You earned ${points} points, ${(
			points / 10
		).toFixed(1)}% of 5$ gift card`;
		this.updateFooter();
	}
	openLink(link) {
		const newWindow = window.open();
		newWindow.opener = null;
		newWindow.location = link;
	}

	states = {
		out: {
			textHTML: "",
			ctaHTML: "",
			colors: { text: "#fff", bg: "#000", ctaText: "#2f7bf6" },
			logo: false,
			action: () => {
				// widget.scrollIntoView();
				// widget.message({
				// 	type: "EVENT",
				// 	name: "COOPJS_SCROLL_WIDGET_CLICK",
				// 	component: "FOOTER",
				// });
			},
		},
		in: {
			textHTML: "You've earned points. Redeem them for rewards",
			ctaHTML: "Claim ➞",
			colors: { text: "#717171", bg: "#fff", ctaText: "#2f7bf6" },
			logo: true,
			action: () => {
				// widget.footer.openEmail();
				// widget.message({
				// 	type: "EVENT",
				// 	name: "COOPJS_REWARDS_CLAIM_CLICK",
				// 	component: "FOOTER",
				// });
			},
		},

		emailSent: {
			textHTML: `✅ Check your email for your rewards!`,
			ctaHTML: "Similar Brands",
			colors: { text: "#717171", bg: "#fff", ctaText: "#2f7bf6" },
			logo: true,
			action: () => {
				// widget.message({
				// 	type: "EVENT",
				// 	name: "COOPJS_COLLECTIVE_CLICK",
				// 	component: "FOOTER",
				// });
				// widget.footer.openLink(widget.footer.baseCollectiveUrl);
			},
		},

		rewardsDisabled: {
			textHTML: `Find similar brands you'll ❤️`,
			ctaHTML: "Show me →",
			colors: { text: "#717171", bg: "#fff", ctaText: "#2f7bf6" },
			logo: true,
			// action: (e) => widget.footer.states.emailSent.action(e),
			action: () => null,
		},

		optedIn: {
			textHTML: `Check out the rewards you've claimed 🤯`,
			ctaHTML: "Show me →",
			colors: { text: "#717171", bg: "#fff", ctaText: "#2f7bf6" },
			logo: true,
			action: (e) => {
				// widget.message({
				// 	type: "EVENT",
				// 	name: "COOPJS_COLLECTIVE_OPTED_CLICK",
				// 	component: "FOOTER",
				// });
				// widget.footer.openLink(widget.footer.makeConsumerUrl());
			},
		},
		ppeIn: {
			textHTML: `Powered by Disco`,
			ctaHTML: "See Similar Brands →",
			colors: { text: "#717171", bg: "#fff", ctaText: "#2f7bf6" },
			logo: true,
			action: () => {
				this.orchestrator.handleTrack({
					payload: {
						name: "COOPJS_COLLECTIVE_CLICK",
						properties: { component: "FOOTER" },
					},
				});
				this.openLink(this.makeConsumerRedirectUrl());
			},
		},
	};

	handleEmailSubmit(email) {
		if (!email) {
			return;
		}
		// widget.message({ type: "REWARDS_CLAIM", email });
		this.emailInput.style.display = "none";
		this.text.style.display = "block";
		setTimeout(() => {
			this.text.style.opacity = "1";
			this.text.style.transform = "scale(1)";
		}, 0);
		this.state = this.states.emailSent;
		this.emailSent = true;
		this.updateFooter();
	}

	handleClick(e) {
		// if (this.emailOpen && !this.emailSent) {
		// 	this.handleEmailSubmit(
		// 		this.emailInput ? this.emailInput.value.trim() : false
		// 	);
		// 	return;
		// }
		if (!this.state) {
			return;
		}
		this.state.action(e);
	}

	applySpecialStyles(styles) {
		applyStyles(this.el, styles);
	}

	mount() {
		const footer = document.createElement("div");
		footer.innerHTML = `
        <section class="coop-footer-logo">
            <img
                src="https://coopcommerce.s3.us-west-1.amazonaws.com/disco-logo.png"
                alt="Disco"
            />
        </section>
        <section class="coop-footer-text"></section>
        <input class="coop-footer-email" type="email" placeholder="Enter your email to claim" />
        <section class="coop-footer-cta"></section>
    `;

		footer.setAttribute("id", "coop-footer");

		this.el = footer;
		this.text = footer.querySelector(".coop-footer-text");
		this.cta = footer.querySelector(".coop-footer-cta");
		this.logo = footer.querySelector(".coop-footer-logo");
		this.emailInput = footer.querySelector(".coop-footer-email");
		this.onWidget = false;
		this.cta.addEventListener("click", this.handleClick.bind(this));
		this.hide();
		document.body.appendChild(footer);
		this.state = this.states.ppeIn;
		this.updateFooter();
	}
	show() {
		if (this.el) {
			this.el.style.display = "flex";
		}
	}
	hide() {
		if (this.el) {
			this.el.style.display = "none";
		}
	}

	unMount() {
		if (this.el) {
			this.el.remove();
		}
	}

	// openEmail() {
	// 	this.emailOpen = true;
	// 	this.text.style.opacity = 0;
	// 	this.text.style.transform = "scale(0.4)";
	// 	// setTimeout(() => {
	// 	// 	if (!widget.user.email) {
	// 	// 		this.text.style.display = "none";
	// 	// 		this.emailInput.style.display = "block";
	// 	// 		this.emailInput.style.opacity = 1;
	// 	// 		this.emailInput.style.transform = "scale(1)";
	// 	// 		this.emailInput.focus();
	// 	// 	} else {
	// 	// 		this.handleEmailSubmit(widget.user.email);
	// 	// 	}
	// 	// }, 200);
	// }

	updateFooter() {
		// if (this.emailOpen && !this.emailSent) {
		// 	return;
		// }
		if (this.cta) {
			this.cta.innerHTML = this.state.ctaHTML;
			this.cta.style.color = this.state.colors.ctaText;
		}
		if (this.text) {
			this.text.innerHTML = this.state.textHTML;
		}
		if (this.el) {
			this.el.style.backgroundColor = this.state.colors.bg;
			this.el.style.color = this.state.colors.text;
		}
		if (this.logo) {
			if (this.state.logo) {
				this.logo.style.display = "block";
			} else {
				this.logo.style.display = "none";
			}
		}
	}

	// updateState() {
	// 	if (this.widget.mode === Widget.MODES.FULLSCREEN) {
	// 		this.state = this.states.ppeIn;
	// 	} else {
	// 		this.state = this.onWidget
	// 			? this.optedIn
	// 				? this.states.optedIn
	// 				: this.rewardsDisabled
	// 				? this.states.rewardsDisabled
	// 				: this.states.in
	// 			: this.states.out;
	// 	}
	// 	this.updateFooter();
	// }

	// set onWidget(onWidget) {
	// 	if (this._onWidget === onWidget || this.emailOpen || this.emailSent) {
	// 		return;
	// 	}
	// 	this._onWidget = onWidget;
	// 	this.updateState();
	// }
	// get onWidget() {
	// 	return this._onWidget;
	// }
}

export default Footer;
