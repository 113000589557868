export const ENV = {
	development: {
		appUrl: "http://localhost:3001/?",
		trackerUrl: "http://localhost:5500/server/static/disco-tracker.html?",

		identifyUrl: "http://localhost:4000/user/",
		apiUrl: "http://localhost:6543/",
		styleUrl: "http://localhost:5500/server/static/coopcommerce_new.css",
		env: "development",
	},
	qa: {
		appUrl: "https://launcher.disconetwork-dev.com/?",
		trackerUrl:
			"https://consumer.disconetwork-dev.com/static/disco-tracker.html?",
		identifyUrl: "https://identity.disconetwork-staging.com/user/",
		apiUrl: "https://consumer.disconetwork-dev.com/",
		styleUrl:
			"https://consumer.disconetwork-dev.com/static/coopcommerce_new.css",
		env: "qa",
	},
	staging: {
		appUrl: "https://launcher.disconetwork-staging.com/?",
		// appUrl: "http://localhost:3001/?",
		trackerUrl:
			"https://consumer.disconetwork-staging.com/static/disco-tracker.html?",
		// trackerUrl: "http://localhost:5500/server/static/disco-tracker.html?",

		identifyUrl: "https://identity.disconetwork-staging.com/user/",
		apiUrl: "https://consumer.disconetwork-staging.com/",
		styleUrl:
			"https://consumer.disconetwork-staging.com/static/coopcommerce_new.css",
		env: "staging",
	},
	production: {
		appUrl: "https://launcher.disconetwork.com/?",
		trackerUrl:
			"https://widget2.disconetwork.com/static/disco-tracker.html?",
		identifyUrl: "https://identity.disconetwork.com/user/",
		apiUrl: "https://consumer.disconetwork.com/",
		styleUrl:
			"https://widget2.disconetwork.com/static/coopcommerce_new.css",
		env: "production",
	},
};
