export const CUSTOM_ANCHOR_POSITION = { BEFORE: "BEFORE", AFTER: "AFTER" };

export const MOUNT_AFTER_MAP = {
	DEFAULT: () => document.querySelector(".section"),
	SECTION_1: () => document.querySelectorAll(".section")[1],
	THANK_YOU_ADDITIONAL_CONTENT: () =>
		document.querySelector(".thank-you__additional-content"),
	CONTENT_BOX_0: () => document.querySelectorAll(".content-box")[0],
	CONTENT_BOX_1: () => document.querySelectorAll(".content-box")[1],
	CONTENT_BOX_2: () => document.querySelectorAll(".content-box")[2],
	CONTENT_BOX_3: () => document.querySelectorAll(".content-box")[3],
	VALID_CONTENT_BOX_AFTER_N: (n) => () => {
		const contentBoxes = document.querySelectorAll(".content-box");
		if (n > contentBoxes.length) return null;
		let reqBox = null;
		for (let i = n; i < contentBoxes.length; i++) {
			if (
				!reqBox &&
				contentBoxes[i] &&
				contentBoxes[i].offsetWidth !== 0
			) {
				reqBox = contentBoxes[i];
			}
		}
		return reqBox;
	},
	CONTENT_BOX_LAST: () =>
		document.querySelectorAll(".content-box")[
			document.querySelectorAll(".content-box").length - 1
		],
	COOP_MOUNT_AFTER: () => document.querySelector("#coop-mount-after"),
	THANK_YOU_CHECKOUT_TEXT: () =>
		document.querySelector(".thank-page-checkout__text"),

	WOOCOMMERCE_ORDER_OVERVIEW: () =>
		document.querySelector(".woocommerce-order-overview"),

	MAIN_CONTENT: () => document.querySelector(".main__content"),
	MOUNT_BEFORE_MAP: () => {
		let osStepTitles = document.querySelectorAll(".os-step__title");
		let map = Array.from(osStepTitles).filter((el) =>
			el.parentElement.classList.contains("text-container")
		);
		if (!map.length) return document.querySelectorAll(".content-box")[0];

		let target = map[0].closest(".content-box");
		const mountAfter = mountCustomAnchor({
			target,
			position: CUSTOM_ANCHOR_POSITION.BEFORE,
		});

		return mountAfter;
	},
	MOUNT_AFTER_POS: (contentBoxIndex) => () => {
		const contentBoxes = document.querySelectorAll(".content-box");
		const totalBoxes = contentBoxes.length;

		if (Math.abs(contentBoxIndex) >= totalBoxes) return null;

		return contentBoxes[
			contentBoxIndex >= 0
				? contentBoxIndex
				: totalBoxes + contentBoxIndex
		];
	},
	MOUNT_UNDER_MAP: () => {
		//This is partially duplicate code with MOUNT_BEFORE_MAP
		//A backlog task will handle making a common utility for getting
		//the map while handling the case of no map properly
		let osStepTitles = document.querySelectorAll(".os-step__title");
		let map = Array.from(osStepTitles).filter((el) =>
			el.parentElement.classList.contains("text-container")
		);
		if (!map.length) return document.querySelectorAll(".content-box")[0];

		let target = map[0].closest(".content-box");

		return target;
	},
	TARGETED_CUSTOM_ANCHOR: (targetCallback) => () => {
		if (!targetCallback || typeof targetCallback !== "function")
			return null;
		const target = targetCallback();
		if (!target) return null;
		const customAnchor = mountCustomAnchor({
			target,
			position: CUSTOM_ANCHOR_POSITION.AFTER,
		});
		return customAnchor;
	},
};

export const mountCustomAnchor = ({
	target = null,
	position = CUSTOM_ANCHOR_POSITION.BEFORE,
} = {}) => {
	if (!target) return null;

	let mountAfter = document.createElement("div");
	mountAfter.id = "coop-mount-after";
	// CAUTION: ensure the mountAfter has all the properties of target
	// which are needed for the script to function properly.
	// These can be found by doing a global search for "mountAfter." in "script/" folder
	if (position === CUSTOM_ANCHOR_POSITION.BEFORE) {
		target.before(mountAfter);
		return mountAfter;
	} else if (position === CUSTOM_ANCHOR_POSITION.AFTER) {
		target.after(mountAfter);
		return mountAfter;
	}

	return null;
};
