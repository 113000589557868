const applyStyles = (ref, styles) => {
	if (
		!ref ||
		typeof styles !== "object" ||
		Object.entries(styles).length === 0
	) {
		return;
	}

	Object.entries(styles).forEach((style) => (ref.style[style[0]] = style[1]));
};
export default applyStyles;
