const GET = (key) => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	return params.get(key);
};
export const GETScript = (key, src = false) => {
	const url = new URL(src ? src : document.currentScript.src);
	const params = new URLSearchParams(url.search);

	return params.get(key);
};

export default GET;
