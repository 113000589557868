import debounce from "./utils/debounce";
import { GETScript } from "./utils/GET";
import log from "./utils/log";

import Overlay from "./frames/Overlay";
import Widget from "./frames/Widget";
import Surface from "./frames/Surface";

import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import { ENV } from "./env";
import { SENTRY_DSN } from "./config";
import Tracker from "./frames/Tracker";
import isFunction from "./utils/isFunction";
import { WIDGET_TYPE, WIDGET_VERSION } from "./utils/configureExperience";

const orchestratorFactory = (config, globalState) => {
	const orchestrator = {
		surfaces: {},
		tracker: null,

		scraper: {
			validateEmail(email) {
				if (!email || typeof email !== "string") {
					return false;
				}
				const re =
					/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return re.test(email.toLowerCase());
			},

			validatePhone(number) {
				if (!number || typeof number !== "string") {
					return null;
				}
				// for removing the left-to-right mark
				number = number.replace(/&lrm;|\u200E/gi, "");
				number = number.trim();
				let stripped = number
					.replace("-", "")
					.replace("(", "")
					.replace(")", "")
					.replace("+", "");

				while (
					stripped.indexOf("-") !== -1 ||
					stripped.indexOf("(") !== -1 ||
					stripped.indexOf(")") !== -1 ||
					stripped.indexOf("+") !== -1 ||
					stripped.indexOf(" ") !== -1
				) {
					stripped = stripped
						.replace("-", "")
						.replace("(", "")
						.replace(")", "")
						.replace("+", "")
						.replace(" ", "");
				}

				if (
					(number.length === 10 && !isNaN(number)) ||
					(stripped.length === 10 && !isNaN(stripped))
				) {
					return isNaN(number) ? stripped : number;
				} else if (
					(number.length === 11 && !isNaN(number)) ||
					(stripped.length === 11 && !isNaN(stripped))
				) {
					if (isNaN(number)) {
						return stripped.substring(1);
					} else {
						return number.substring(1);
					}
				} else {
					return null;
				}
			},

			validateName(name) {
				if (
					!name ||
					name.toLowerCase().indexOf("thank you") === -1 ||
					name.toLowerCase().indexOf("order") !== -1
				) {
					return null;
				}
				let nameStripped = name
					.toLowerCase()
					.replace("thank you", "")
					.replace("!", "")
					.replace(",", "")
					.trim();

				if (nameStripped.length > 12 || nameStripped.length < 2) {
					return null;
				}

				nameStripped = `${nameStripped
					.charAt(0)
					.toUpperCase()}${nameStripped.slice(1)}`;

				return nameStripped || null;
			},

			validateOrder(order) {
				if (
					!order ||
					typeof order !== "string" ||
					order.toLowerCase().indexOf("order #") === -1 ||
					order.toLowerCase().indexOf("thank you") !== -1
				) {
					return false;
				}

				const orderStripped = order
					.trim()
					.toLowerCase()
					.replace("order #", "")
					.replace("your", "")
					.replace("is", "")
					.replace("confirmed.", "")
					.trim();

				if (orderStripped.length > 15) {
					return false;
				}
				return orderStripped || false;
			},

			validateConfirmationID(num) {
				if (!num || typeof num !== "string") return false;
				let formattedNum = num.toLowerCase();

				if (
					formattedNum.indexOf("confirmation #") === -1 ||
					formattedNum.toLowerCase().indexOf("thank you") !== -1
				) {
					return false;
				}

				formattedNum = formattedNum
					.trim()
					.toLowerCase()
					.replace("confirmation", "")
					.replace("#", "")
					.trim();

				if (formattedNum.length > 15) return false;
				formattedNum = `CONF_${formattedNum}`;
				return formattedNum || false;
			},

			scrapeCustomOrder() {
				let scrapeType = false;
				let targetEl = null;

				if (!targetEl) {
					targetEl = document.querySelector(
						".woocommerce-order-overview__order"
					);
					if (targetEl) {
						scrapeType = "WOOCOMMERCE";
					}
				}

				if (!targetEl) {
					targetEl = document.querySelector(".order-number");
					if (targetEl) {
						scrapeType = "HONEST_CO";
					}
				}
				if (!targetEl) {
					return false;
				}
				let orderStripped;
				switch (scrapeType) {
					case "WOOCOMMERCE":
						if (
							targetEl.innerText
								.trim()
								.toLowerCase()
								.indexOf("order") === -1
						) {
							return false;
						}
						orderStripped = targetEl.innerText
							.trim()
							.toLowerCase()
							.replace("order", "")
							.replace("number", "")
							.replace(":", "")
							.trim();
						if (orderStripped.length > 15) {
							return false;
						}
						return orderStripped || false;

					case "HONEST_CO":
						if (!targetEl.innerText || !targetEl.innerText.trim()) {
							return false;
						}
						orderStripped = targetEl.innerText
							.trim()
							.toLowerCase()
							.trim();
						return orderStripped || false;

					default:
						return false;
				}
			},

			RECHARGE_IDENTIFIER: "/r/purchase",

			scrapeOrder() {
				const targetEl = document.querySelector(".os-order-number");
				if (!targetEl) {
					return this.scrapeCustomOrder();
				}
				if (
					targetEl.innerText.trim().toLowerCase().indexOf("order") ===
					-1
				) {
					return false;
				}

				const orderStripped = targetEl.innerText
					.trim()
					.toLowerCase()
					.replace("order", "")
					.replace("#", "")
					.trim();
				if (orderStripped.length > 15) {
					return false;
				}

				return orderStripped || false;
			},

			scrapeConfirmationID() {
				const targetEl = document.querySelector(".os-order-number");
				if (!targetEl) {
					return false;
				}
				const num = this.validateConfirmationID(targetEl.innerText);
				return num || false;
			},

			inRecharge() {
				return (
					document.URL.indexOf(
						orchestrator.scraper.RECHARGE_IDENTIFIER
					) !== -1
				);
			},

			getDetails() {
				const all = document.body.querySelectorAll("*");
				let email = null,
					phone = null,
					checkoutPhone = null,
					first_name = null,
					identifier = null,
					confirmationId = null,
					contactEmail = null,
					shipping;

				let foundFromShopify = {};

				if (document.querySelector(".coopcommerce-order-id")) {
					identifier = document.querySelector(
						".coopcommerce-order-id"
					).innerText;
				}

				let googleUsedId = Array.isArray(window.dataLayer)
					? window.dataLayer.find((obj) => !!obj.userId)
					: null;

				if (googleUsedId) {
					googleUsedId = googleUsedId.userId;
				}

				let billingId = null,
					shippingId = null,
					customerId = null,
					createdAt = null,
					token = null,
					enrichment_billing_zip_code = null,
					enrichment_shipping_zip_code = null;

				// Wouldn't it be nice to use optional chaining

				const hasShopifyObject = !!window.Shopify;
				const hasShopifyCheckoutObject =
					hasShopifyObject && !!Shopify.checkout;

				if (hasShopifyCheckoutObject) {
					if (
						Shopify.checkout.shipping_rate &&
						Shopify.checkout.shipping_rate.title
					) {
						shipping = Shopify.checkout.shipping_rate.title;
					}
					if (Shopify.checkout.email) {
						email = Shopify.checkout.email;
						foundFromShopify.email = true;
					}
					if (Shopify.checkout.phone) {
						phone = this.validatePhone(Shopify.checkout.phone);
						checkoutPhone = phone;
						if (!phone && Shopify.checkout.shipping_address) {
							phone = this.validatePhone(
								Shopify.checkout.shipping_address.phone
							);
						}
						if (!phone && Shopify.checkout.billing_address) {
							phone = this.validatePhone(
								Shopify.checkout.billing_address.phone
							);
						}
						if (phone) {
							foundFromShopify.phone = true;
						}
					}
					if (
						!first_name &&
						Shopify?.checkout?.billing_address?.first_name
					) {
						first_name = Shopify.checkout.billing_address.first_name;
						foundFromShopify.name = true;
					}
					if (
						!first_name &&
						Shopify?.checkout?.shipping_address?.first_name
					) {
						first_name = Shopify.checkout.shipping_address.first_name;
						foundFromShopify.name = true;
					}
					if (
						Shopify.checkout.billing_address &&
						Shopify.checkout.billing_address.id
					) {
						billingId = Shopify.checkout.billing_address.id;
					}
					if (
						Shopify.checkout.billing_address &&
						Shopify.checkout.billing_address.zip
					) {
						enrichment_billing_zip_code =
							Shopify.checkout.billing_address.zip;
					}
					if (
						Shopify.checkout.shipping_address &&
						Shopify.checkout.shipping_address.id
					) {
						shippingId = Shopify.checkout.shipping_address.id;
					}
					if (
						Shopify.checkout.shipping_address &&
						Shopify.checkout.shipping_address.zip
					) {
						enrichment_shipping_zip_code =
							Shopify.checkout.shipping_address.zip;
					}
					createdAt = Shopify.checkout.created_at;
					customerId = Shopify.checkout.customer_id;

					token = Shopify.checkout.token;
				}
				const inRecharge = this.inRecharge();

				const maybeEmails = [];
				all.forEach((el) => {
					if (el.tagName !== "SCRIPT") {
						if (
							(!hasShopifyCheckoutObject || inRecharge) &&
							!foundFromShopify?.email &&
							this.validateEmail(el.innerText)
						) {
							const maybeEmail = el.innerText.toLowerCase
								? el.innerText.toLowerCase()
								: el.innerText;
							maybeEmails.push(maybeEmail);
						}
						if (
							(!hasShopifyCheckoutObject || inRecharge) &&
							!foundFromShopify?.phone &&
							el.innerText &&
							el.innerText.length < 400 &&
							!el.getAttribute("href") &&
							(!el.innerHTML ||
								el.innerHTML.indexOf("tracking") === -1)
						) {
							let details = el.innerText.split("\n");
							details.forEach((detail) => {
								phone = phone || this.validatePhone(detail);
							});
						}
						if (
							(!hasShopifyCheckoutObject || inRecharge) &&
							!foundFromShopify?.name &&
							!first_name
						) {
							first_name = this.validateName(el.innerText);
						}

						if (!identifier) {
							identifier = this.validateOrder(el.innerText);
						}
						if (!confirmationId) {
							confirmationId = this.validateConfirmationID(
								el.innerText
							);
						}

						if (
							!contactEmail &&
							el.innerText &&
							el.innerText.trim().toLowerCase() ===
								"need help? contact us"
						) {
							const link = el.querySelector("a");
							if (!link || !link.href) {
								return;
							}
							const maybeContact = link.href
								.toLowerCase()
								.split("mailto:")[1];
							if (
								!maybeContact ||
								!this.validateEmail(maybeContact)
							) {
								return;
							}
							contactEmail = maybeContact.toLowerCase
								? maybeContact.toLowerCase()
								: maybeContact;
						}
					}
				});

				if (maybeEmails.length > 0) {
					try {
						email =
							maybeEmails
								.filter(
									(maybeEmail) => maybeEmail !== contactEmail
								)
								.slice(-1)[0] || null;
					} catch (err) {
						email = null;
					}
				}

				let step = null;

				const stepEl = Array.from(
					document.querySelectorAll(".os-timeline-step")
				).find((e) => e.getAttribute("aria-current"));

				if (
					stepEl &&
					stepEl.querySelector(".os-timeline-step__title")
				) {
					step = stepEl
						.querySelector(".os-timeline-step__title")
						.innerText.trim()
						.toLowerCase();
				}

				let trackingInfo = document.querySelector(".tracking-info")
					? document.querySelector(".tracking-info").innerText.trim()
					: null;

				let trackingLink = trackingInfo
					? document
							.querySelector(".tracking-info")
							.querySelector(
								"a[aria-describedby='forwarding-external-new-window-message']"
							)
					: null;

				trackingLink = trackingLink ? trackingLink.href : null;

				if (!identifier) {
					identifier = this.scrapeOrder();
				}

				if (!confirmationId) {
					confirmationId = this.scrapeConfirmationID();
				}

				if (!email) {
					const els = document.querySelectorAll(
						"[data-bind='text: getEmailAddress()']"
					);
					if (els.length > 0) {
						Array.from(els).forEach((el) => {
							if (!email && this.validateEmail(el.innerText)) {
								email = el.innerText.toLowerCase();
							}
						});
					}
				}

				globalState.user.email = email;
				globalState.user.phone = phone;
				globalState.user.first_name = first_name;
				globalState.user.identifier = identifier;

				return {
					email,
					phone,
					checkout_phone: checkoutPhone,
					first_name: first_name,
					identifier,
					step,
					trackingInfo,
					trackingLink,
					contactEmail,
					shipping,
					confirmation_id: confirmationId,
					shipping_id: shippingId,
					billing_id: billingId,
					customer_id: customerId,
					created_at: createdAt,
					shopify_checkout_token: token,
					google_user_id: googleUsedId,
					host: document.URL,
					enrichment_billing_zip_code,
					enrichment_shipping_zip_code,
				};
			},
			getPurchases() {
				const purchases = Array.from(
					document.querySelectorAll("tr.product")
				)
					.map((elem) => ({
						product_id: elem.getAttribute("data-product-id"),
						type: elem.getAttribute("data-product-type"),
						name: elem.querySelector(
							"span.product__description__name"
						),
						variant: elem.querySelector(
							"span.product__description__variant"
						),
						quantity: elem.querySelector("td.product__quantity"),
					}))
					.map((purchase) => ({
						...purchase,
						name: purchase.name
							? purchase.name.innerText.trim()
							: null,
						variant: purchase.variant
							? purchase.variant.innerText.trim()
							: null,
						quantity: purchase.quantity
							? purchase.quantity.innerText.trim()
							: null,
						scraped: true,
					}));
				if (
					window.Shopify &&
					Shopify.checkout &&
					Shopify.checkout.line_items &&
					Array.isArray(Shopify.checkout.line_items)
				) {
					const shopifyPurchases = Shopify.checkout.line_items.map(
						(shopifyPurchase) => ({
							product_id: shopifyPurchase.product_id,
							name: shopifyPurchase.title,
							variant: shopifyPurchase.variant_title,
							quantity: shopifyPurchase.quantity,
							scraped: false,
						})
					);
					shopifyPurchases.forEach((shopifyPurchase) => {
						purchases.push(shopifyPurchase);
					});
				}

				const res = JSON.stringify(purchases);

				if (res.length > 3100) {
					return "";
				}

				return res;
			},

			getShopifyOrderID() {
				if (
					!window.Shopify ||
					!Shopify.checkout ||
					!Shopify.checkout.order_id
				) {
					return null;
				}
				return Shopify.checkout.order_id;
			},

			getShopifyDiscountCode() {
				if (
					!window.Shopify ||
					!Shopify.checkout ||
					!Shopify.checkout.discount ||
					!Shopify.checkout.discount.code
				) {
					return null;
				}
				return Shopify.checkout.discount.code;
			},

			getCost() {
				let cost = document.querySelector(".payment-due__price");
				if (
					!cost || // not found
					cost.innerText.trim() === "" // hasn't loaded yet
				) {
					cost = document.querySelector(".total-recap__final-price");
				}
				if (
					!cost || // not found
					cost.innerText.trim() === "" // hasn't loaded yet
				) {
					// get cost for woocommerce
					cost = document.querySelector(".woocommerce-Price-amount");
				}
				if (
					(!cost || cost.innerText.trim() === "") &&
					(config.PUBLISHER ===
						"95a21c28-61c4-4d4f-9b4b-c64f556d362b" ||
						config.PUBLISHER ===
							"a8d9f04b-dd6a-4a0b-8fd1-670abd77358f")
				) {
					// get cost for Honest co
					cost = document.querySelector(".grand-total");
				}

				if (
					(!cost || cost.innerText.trim() === "") &&
					config.PUBLISHER === "cd57f149-fd10-4cce-80db-193febe6b141"
				) {
					// get cost for Lumin
					cost = document.querySelector(".chakra-text.css-18zf6id")
						? document.querySelector(".chakra-text.css-18zf6id")
								.innerText
						: "";

					if (cost) {
						cost = cost.substring(1);
					}
				}

				cost = cost && cost.innerText.trim();
				return cost;
			},
		},

		getApiPayload() {
			// THIS FUNCTION IS ALSO BEING USED IN SHOW(REMOTEDATA)
			// BEFORE MAKING CHANGES TO THIS, DOUBLE CHECK THE "SHOW" OF SURFACES (WIDGET + OVERLAY)

			const scraperDetails = this.scraper.getDetails();
			return {
				...scraperDetails,
				purchases: this.scraper.getPurchases(),
				shopify_checkout_token:
					scraperDetails?.shopify_checkout_token || "",
				shopify_order_id: this.scraper.getShopifyOrderID() || null,
				shopify_discount_code:
					this.scraper.getShopifyDiscountCode() || null,
				cost: this.scraper.getCost(),
				// added later
				identifier: scraperDetails?.identifier || null,
				confirmation_id: scraperDetails?.confirmation_id || null,
				first_name: scraperDetails?.first_name || null,
				e: scraperDetails.email,
				url: scraperDetails.host,
				p: this.scraper.getPurchases(),
				c: this.scraper.getCost(),
				shipping_method: scraperDetails?.shipping,
				recommender_mechanism: config.RECOMMENDER_MECHANISM,
				enrichment_billing_zip_code:
					scraperDetails?.enrichment_billing_zip_code,
				enrichment_shipping_zip_code:
					scraperDetails?.enrichment_shipping_zip_code,
			};
		},

		dataLoader: {
			async load() {
				let data, responses;
				const payload = orchestrator.getApiPayload();

				try {
					responses = await Promise.all(
						Object.values(orchestrator.surfaces).map((surface) =>
							surface.getApiRequest(payload)
						)
					);
				} catch (error) {
					console.error("co-op API Error:", error);
					return orchestrator.unMountAll();
				}

				if (!responses.reduce((acc, resp) => acc && resp.ok, true)) {
					console.error("co-op API Error");
					return orchestrator.unMountAll();
				}

				// data = [for widget (/push), for overlay (/modules)]
				data = await Promise.all(
					responses.map((response) => response.json())
				);
				globalState.dataLoaded = true;
				globalState.activeWidgetType =
					data?.[0]?.widget_type in WIDGET_TYPE
						? WIDGET_TYPE[data[0].widget_type]
						: WIDGET_TYPE.DISCOFEED;

				globalState.activeWidgetVersion =
					data?.[0]?.widget_version in
					WIDGET_VERSION[globalState.activeWidgetType]
						? WIDGET_VERSION[globalState.activeWidgetType][
								data[0].widget_version
						  ]
						: WIDGET_VERSION[
								globalState.activeWidgetType
						  ].PRODUCTION();

				globalState.runningExperiments = data?.[0]?.running_experiments;

				Object.values(orchestrator.surfaces).forEach(
					(surface, index) => {
						surface.addAvailableListener((reMount) =>
							surface.show(data[index], reMount)
						);
					}
				);

				orchestrator.tracker.addAvailableListener((reMount) =>
					orchestrator.tracker.show(data[0], reMount)
				);
			},
		},

		unMountAll() {
			console.warn("Unmounting app co-op surfaces");
			Object.values(this.surfaces).forEach((surface) =>
				surface.unMount()
			);
		},

		computeDerivedStyles() {
			const dummyBtnEl = document.createElement("div");
			dummyBtnEl.style.width = 0;
			dummyBtnEl.style.height = 0;
			dummyBtnEl.style.display = "none";
			dummyBtnEl.className = "btn disco-style-btn";
			document.body.appendChild(dummyBtnEl);

			if (typeof window?.getComputedStyle !== "function") return;

			const dummyBtnComputedStyles = window.getComputedStyle(dummyBtnEl);
			const themeBackgroundColor =
				dummyBtnComputedStyles?.backgroundColor;
			const themeForegroundColor = dummyBtnComputedStyles?.color;

			if (typeof themeBackgroundColor === "string")
				globalState.shopTheme.backgroundColor = themeBackgroundColor;
			if (typeof themeForegroundColor === "string")
				globalState.shopTheme.foregroundColor = themeForegroundColor;

			if (dummyBtnEl) {
				dummyBtnEl.remove();
			}
		},

		mountResources() {
			this.computeDerivedStyles();

			// Mount font tag
			const fontTag = document.createElement("link");
			fontTag.href = config.FONT_URL;
			fontTag.rel = "stylesheet";
			document.head.appendChild(fontTag);

			// Mount style tag
			const coopStyleTag = document.createElement("link");
			coopStyleTag.href = config.ACTIVE_ENV.styleUrl;
			coopStyleTag.rel = "stylesheet";
			coopStyleTag.type = "text/css";
			document.head.appendChild(coopStyleTag);
		},

		allSurfacesAvailable() {
			return Object.values(this.surfaces).reduce(
				(done, surface) =>
					done && surface.state === Surface.states.AVAILABLE,
				true
			);
		},

		updateParentStats: debounce(() => {
			let messagePayload = {
				documentScrollTop: document.documentElement.scrollTop,
				windowInnerHeight: window.innerHeight,
				windowInnerWidth: window.innerWidth,
			};
			Object.values(orchestrator.surfaces).forEach((surface) => {
				if (!surface.iFrame) {
					return;
				}
				const boundingClientRect =
					surface.iFrame.getBoundingClientRect();
				const { top, height } = boundingClientRect || {};
				const { windowInnerHeight } = messagePayload;
				const isFrameInViewport =
					height &&
					((top >= 0 && top <= windowInnerHeight) ||
						(top + height >= 0 &&
							top + height <= windowInnerHeight));

				messagePayload = {
					...messagePayload,
					[surface.idx.replace("coop-", "")]: {
						boundingClientRect,
						visibleFrameStart:
							boundingClientRect.top > 0
								? 0
								: Math.abs(boundingClientRect.top),
						visibleFrameEnd:
							boundingClientRect.top + boundingClientRect.height <
							0
								? 0
								: Math.min(
										boundingClientRect.height,
										window.innerHeight -
											boundingClientRect.top
								  ),
						isFrameInViewport,
					},
				};
			});
			Object.values(orchestrator.surfaces).forEach((surface) => {
				surface.message("UPDATE_PARENT_STATS", messagePayload);
				if (isFunction(surface.handleParentStats)) {
					surface.handleParentStats(messagePayload);
				}
			});
		}, 20),

		attachEvents() {
			window.addEventListener("scroll", this.updateParentStats);
		},

		signalGlobalState(cb) {
			if (isFunction(cb)) cb();
			Object.values(orchestrator.surfaces).forEach((surface) =>
				surface.message("UPDATE_GLOBAL_STATE", globalState)
			);
		},

		launch() {
			const messageCallbacks = {
				TRACK: this.handleTrack.bind(this),
				UPDATE_REMOTE_USER: this.handleUpdateRemoteUser.bind(this),
			};

			this.surfaces = {
				widget: new Widget({
					config,
					globalState,
					orchestrator: this,
					messageCallbacks,
				}),
				overlay: new Overlay({
					config,
					globalState,
					orchestrator: this,
					messageCallbacks,
				}),
			};

			this.tracker = new Tracker({
				config,
				globalState,
				orchestrator: this,
				signalGlobalState: this.signalGlobalState,
			});

			this.mountResources();
			Object.values(this.surfaces).forEach((surface) => {
				surface.launch();
			});
			this.tracker.launch();

			this.attachEvents();
			this.dataLoader.load();

			// setTimeout(() => {
			// 	this.surfaces[0].setMode(Widget.MODES.FULLSCREEN);
			// }, 3000);

			// setTimeout(() => {
			// 	this.surfaces[0].setMode(Widget.MODES.INLINE);
			// }, 8000);
		},

		onReady(fn) {
			if (document.readyState === "loading") {
				document.addEventListener("DOMContentLoaded", fn);
			} else {
				fn();
			}
		},

		initSentry() {
			Sentry.init({
				dsn: SENTRY_DSN,
				integrations: [new BrowserTracing()],
				tracesSampleRate: 1.0,
				ignoreErrors: [
					// Random plugins/extensions
					"top.GLOBALS",
					// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
					"originalCreateNotification",
					"canvas.contentDocument",
					"MyApp_RemoveAllHighlights",
					"http://tt.epicplay.com",
					"Can't find variable: ZiteReader",
					"jigsaw is not defined",
					"ComboSearch is not defined",
					"http://loading.retry.widdit.com/",
					"atomicFindClose",
					// Facebook borked
					"fb_xd_fragment",
					// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
					// reduce this. (thanks @acdha)
					// See http://stackoverflow.com/questions/4113268
					"bmi_SafeAddOnload",
					"EBCallBackMessageReceived",
					// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
					"conduitPage",
				],
				denyUrls: [
					// Facebook flakiness
					/graph\.facebook\.com/i,
					// Facebook blocked
					/connect\.facebook\.net\/en_US\/all\.js/i,
					// Woopra flakiness
					/eatdifferent\.com\.woopra-ns\.com/i,
					/static\.woopra\.com\/js\/woopra\.js/i,
					// Chrome extensions
					/extensions\//i,
					/^chrome:\/\//i,
					// Other plugins
					/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
					/webappstoolbarba\.texthelp\.com\//i,
					/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
				],
				allowUrls: [
					/https?:\/\/((widget|widget2|www)\.)?coopcommerce\.co/,
					/https?:\/\/((widget|widget2|www)\.)?coopcommerce\.com/,
					"https://d1zcmvsklxjbur.cloudfront.net",
					"coopcommerce.co",
					"coopcommerce.com",

					/https?:\/\/((widget|widget2|www)\.)?disconetwork\.co/,
					/https?:\/\/((widget|widget2|www)\.)?disconetwork\.com/,
					"https://d1zcmvsklxjbur.cloudfront.net",
					"disconetwork.co",
					"disconetwork.com",
				],
			});
		},

		handleTrack({ payload }) {
			if (!payload || !this.tracker) return;
			if (payload.name) {
				this.tracker.track(payload.name, payload.properties);
			}
			if (payload.snowplow) {
				this.tracker.snowplowTrack(payload.snowplow);
			}
		},

		handleUpdateRemoteUser({ payload }) {
			this.tracker.identifyRemoteUser({ payload });
		},

		init() {
			if (window.coopcommerceLoaded) return;
			window.coopcommerceLoaded = true;

			// this.initSentry();

			log("INIT CALLED at : ", Date.now());

			const testmode = document.currentScript.getAttribute("testmode");
			if (testmode === "true") {
				config.TEST_MODE = true;
			} else {
				config.TEST_MODE = false;
			}

			config.PUBLISHER =
				document.currentScript.getAttribute("publisher_id");

			if (!config.PUBLISHER) {
				config.PUBLISHER = GETScript("publisher_id");
			}

			if (!config.PUBLISHER) {
				return log("ERR: NO PUBLISHER ID FOUND");
			}

			config.disableTracking = !!GETScript("dt");

			let SCRIPT_ENV = document.currentScript.getAttribute("environment");
			let SCRIPT_PERF =
				document.currentScript.getAttribute("monitor_performance") ===
				"true";

			if (!SCRIPT_ENV) {
				SCRIPT_ENV = GETScript("environment");
			}

			if (!SCRIPT_ENV || !(SCRIPT_ENV in ENV)) {
				SCRIPT_ENV = "production";
			}

			if (!SCRIPT_PERF) {
				SCRIPT_PERF = GETScript("monitor_performance") === "true";
			}

			config.setupConfig(SCRIPT_ENV, SCRIPT_PERF);

			const SCRIPT_PREVIEW_EXPERIENCE =
				document.currentScript.getAttribute("preview_experience");

			config.setPreviewExperience({
				experience: SCRIPT_PREVIEW_EXPERIENCE,
			});

			config.RECOMMENDER_MECHANISM = document.currentScript.getAttribute(
				"recommender_mechanism"
			);

			/*
                TEST ON THESE MERCHANTS
                alwaysOld: [
                    "2472258c-9794-46cd-8193-891cef6f6b49",
                    "7372b738-6cec-46d1-a30b-ffbd6209f688",
                    "30d902c2-d8ed-4c19-8c62-8cabbf2e47a9",
                ],
                const oldAttr = document.currentScript.getAttribute("show_old");
                const showOld =
                    (oldAttr && oldAttr === "yes") ||
                    this.alwaysOld.indexOf(this.publisher) !== -1;
    
                const fn = showOld
                    ? this.loadModal.bind(this)
                    : this.launch.bind(this);
            */

			const launcher = this.launch.bind(this);

			if (!config.isSpecialPublisher("delayLoadDuration")) {
				return this.onReady(launcher);
			}

			setTimeout(() => {
				this.onReady(launcher);
			}, config.getPublisherProperty("delayLoadDuration"));
		},
	};

	return orchestrator;
};

export default orchestratorFactory;
