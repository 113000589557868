import addUrlParam from "./utils/addUrlParam";
import probability from "./utils/probability";

import {
	DEFAULT_PUBLISHER_PROPERTIES,
	SPECIAL_PUBLISHERS_PROPERTIES,
} from "./publisherProperties";
import { ENV } from "./env";
import { WIDGET_TYPE } from "./utils/configureExperience";

export const DEBUG = false;
export const SENTRY_DSN =
	"https://7c54306aca094566a0e142645e9325f8@o1192235.ingest.sentry.io/6352957";

const config = {
	TEST_MODE: false,
	ACTIVE_ENV: false,
	FONT_URL:
		"https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap",
	MOB_WIDTH: 1000,
	RECHARGE_IDENTIFIER: "/r/purchase",

	PUBLISHER: "",
	MONITOR_PERFORMANCE: false,
	INITIAL_INLINE_HEIGHT: "100px",
	BASE_CONSUMER_URL: `https://www.shopdisco.com/?utm_source=feed-footer&utm_medium=web`,

	PREVIEW_EXPERIENCE: null,

	setPreviewExperience({ experience = null } = {}) {
		const currLocation = window?.location?.href || null;
		// Do not set preview experience if:
		// - not in the right template
		// - or invalid experience type is provided
		if (
			currLocation.indexOf("templates/widget_preview_template.html") == -1
		)
			return;

		if (!experience || !(experience in WIDGET_TYPE)) return;

		this.PREVIEW_EXPERIENCE = experience;
	},

	makeConsumerRedirectUrl({ globalState }) {
		const { email, name } = globalState?.user || {};
		const url = `${this.BASE_CONSUMER_URL}&utm_campaign=${
			this.PUBLISHER || "web"
		}${email ? `&email=${email}` : ""}${
			this.PUBLISHER ? `&publisher_id=${this.PUBLISHER}` : ""
		}${name ? `&name=${name}` : ""}`;
		return url;
	},

	setupConfig(scriptEnv, scriptPerf) {
		this.ACTIVE_ENV = ENV[scriptEnv];
		this.MONITOR_PERFORMANCE = scriptPerf;
	},

	isSpecialPublisher(property = false) {
		if (
			!this.PUBLISHER ||
			!(this.PUBLISHER in SPECIAL_PUBLISHERS_PROPERTIES)
		) {
			return false;
		}
		if (!property) {
			return true;
		}
		if (!(property in SPECIAL_PUBLISHERS_PROPERTIES[this.PUBLISHER])) {
			return false;
		}

		if (
			typeof SPECIAL_PUBLISHERS_PROPERTIES[this.PUBLISHER][property] ===
			"object"
		) {
			if (
				Object.entries(
					SPECIAL_PUBLISHERS_PROPERTIES[this.PUBLISHER][property]
				).length === 0
			) {
				return false;
			} else {
				return true;
			}
		}

		return !!SPECIAL_PUBLISHERS_PROPERTIES[this.PUBLISHER][property];
	},

	getPublisherProperty(property) {
		if (
			!property ||
			!this.PUBLISHER ||
			!(this.PUBLISHER in SPECIAL_PUBLISHERS_PROPERTIES) ||
			!(property in SPECIAL_PUBLISHERS_PROPERTIES[this.PUBLISHER])
		) {
			return;
		}
		return {
			...DEFAULT_PUBLISHER_PROPERTIES,
			...SPECIAL_PUBLISHERS_PROPERTIES[this.PUBLISHER],
		}[property];
	},

	makeUrl(surfaceType = null) {
		if (!surfaceType) {
			return "";
		}

		let url = addUrlParam(
			this.ACTIVE_ENV.appUrl,
			"surfaceType",
			surfaceType
		);
		url = addUrlParam(url, "surfaceIdx", `coop-${surfaceType}`);
		url = addUrlParam(url, "environment", this.ACTIVE_ENV.env);
		url = addUrlParam(url, "publisher", this.PUBLISHER);
		url = addUrlParam(url, "monitorPerformance", this.MONITOR_PERFORMANCE);
		return url;
	},

	lockBodyScroll(globalState) {
		if (this.getPublisherProperty("preventBodyScrollLock")) {
			return;
		}
		if (globalState.hasLockedBodyPos) {
			return;
		}
		document.body.style.overflow = "hidden";

		globalState.originalBodyPos = document.body.style.position;
		globalState.hasLockedBodyPos = true;

		document.body.style.position = "fixed";
	},

	unlockBodyScroll(globalState) {
		if (this.getPublisherProperty("preventBodyScrollLock")) {
			return;
		}
		if (!globalState.hasLockedBodyPos) {
			return;
		}
		document.body.style.overflow = "auto";
		document.body.style.position = globalState.originalBodyPos;
		globalState.hasLockedBodyPos = false;
	},
};

export default config;
