import applyStyles from "../utils/applyStyles";
import Frame from "./Frame";

class Surface extends Frame {
	// ENSURE THAT THESE ARE IN SYNC WITH THE WIDGET AT ALL TIMES
	static MODES = {
		INLINE: 0,
		FULLSCREEN: 1,
		FULLSCREEN_COLLAPSED: 2,
		MODAL: 3,
	};

	open = false;
	hideInitially = true;
	trackingStarted = false;
	initialMarginBottom = 0;
	mode = Surface.MODES.INLINE;

	constructor({
		mode = Surface.MODES.INLINE,
		messageCallbacks = {},
		orchestrator,
		hideInitially,
		...rest
	}) {
		super({
			messageCallbacks: {
				MODE_FULLSCREEN: () => this.setMode(Surface.MODES.FULLSCREEN),
				MODE_INLINE: () => this.setMode(Surface.MODES.INLINE),
				MODE_FULLSCREEN_COLLAPSED: () =>
					this.setMode(Surface.MODES.FULLSCREEN_COLLAPSED),
				REQUEST_PARENT_STATS: () => orchestrator.updateParentStats(),
				REQUEST_GLOBAL_STATE: () => orchestrator.signalGlobalState(),
				UNMOUNT: () => this.unMount(),
				CLOSE: () => this.close(),
				...messageCallbacks,
			},
			orchestrator,
			...rest,
		});

		this.mode = mode;
		this.hideInitially = hideInitially;
	}

	animateIn() {
		if (!this.iFrame) {
			return;
		}
		this.open = true;
		this.iFrame.style.opacity = 1;
		this.iFrame.style.transform = "translateY(0px)";
	}

	animateOut() {
		if (!this.iFrame) {
			return;
		}
		this.iFrame.style.opacity = 0;
		this.open = false;
		setTimeout(() => {
			this.iFrame.style.display = "none";
		}, 201);
	}

	startTrack() {
		if (this.config.TEST_MODE) {
			return;
		}
		this.trackingStarted = true;
		this.message("START_TRACK");
	}

	applySpecialStyles() {
		if (this.mode === Surface.MODES.MODAL) {
			let reqStyles =
				this.config.getPublisherProperty("modalStyles") || {};
			applyStyles(this.iFrame, reqStyles);
			return;
		}

		// make sure it is only called in either inline or fullscreen mode
		const isInlineMode = this.mode === Surface.MODES.INLINE;
		let reqStyles =
			this.config.getPublisherProperty(
				isInlineMode ? "inlineStyles" : "fullscreenStyles"
			) || {};
		let otherStyles =
			this.config.getPublisherProperty(
				isInlineMode ? "fullscreenStyles" : "inlineStyles"
			) || {};

		for (const style in otherStyles) {
			if (!(style in reqStyles)) {
				this.iFrame.style[style] = "";
			}
		}
		if (this.footer) {
			// Using this clause to dynamically update
			// zIndex of widget footer
			this.footer.applySpecialStyles({ zIndex: "" });
			if ("zIndex" in reqStyles) {
				const { zIndex } = reqStyles;
				this.footer.applySpecialStyles({ zIndex });
			}
		}
		const isFullscreenCollapsed =
			this.mode === Surface.MODES.FULLSCREEN_COLLAPSED;

		if (isFullscreenCollapsed) {
			// Only apply zIndex related special styles to fullscreen collapsed frame
			reqStyles = "zIndex" in reqStyles ? { zIndex } : {};
		}
		applyStyles(this.iFrame, reqStyles);
	}

	setDims() {
		this.configProperties();
		if (this.mode === Surface.MODES.INLINE) {
			// this.makeSurfaceTransparent();
			this.iFrame.style.width = `${this.mountAfter.offsetWidth}px`;
			this.iFrame.style.marginBottom = this.mountAfter.style.marginBottom;

			// ONLY SET INITIAL INLINE HEIGHT IF NOT AVAILABLE
			// IF AVAILABLE, USE CSS CLASS TO SET HEIGHT [COOP-WIDGET]
			this.iFrame.style.height =
				this.state !== Surface.states.AVAILABLE
					? this.config.INITIAL_INLINE_HEIGHT
					: "";

			this.applySpecialStyles();

			if (
				this.config.isSpecialPublisher("inlineCallback") &&
				typeof this.config.getPublisherProperty("inlineCallback") ===
					"function"
			) {
				this.config.getPublisherProperty("inlineCallback")(this.iFrame);
			}
		}

		if (this.mode === Surface.MODES.FULLSCREEN) {
			this.iFrame.style.width = "100%";
			this.iFrame.style.marginBottom = "0px";

			this.applySpecialStyles();

			if (
				this.config.isSpecialPublisher("fullscreenCallback") &&
				typeof this.config.getPublisherProperty(
					"fullscreenCallback"
				) === "function"
			) {
				this.config.getPublisherProperty("fullscreenCallback")(
					this.iFrame
				);
			}
		}
		if (this.mode === Surface.MODES.FULLSCREEN_COLLAPSED) {
			this.iFrame.style.width = "100%";
			this.iFrame.style.marginBottom = "0px";

			this.applySpecialStyles();
		}

		if (this.mode === Surface.MODES.MODAL) {
			this.applySpecialStyles();
		}
	}

	// initial -> pre-mount -> mount -> available -> show

	show(remoteData, reMount) {
		console.error("Unimplemented surface.show()");
	}

	close() {
		this.animateOut();
		setTimeout(() => {
			this.unMount();
		}, 5000);
	}

	configProperties() {
		if (!this.globalState.dataLoaded && this.hideInitially) {
			if (
				typeof this.iFrame.className === "string" &&
				this.iFrame.className.indexOf("coop-widget-hidden") === -1
			) {
				this.iFrame.className = `${this.iFrame.className} coop-widget-hidden`;
			}
		} else {
			if (typeof this.iFrame.className === "string") {
				this.iFrame.className = this.iFrame.className.replace(
					"coop-widget-hidden",
					""
				);
			}
		}
		// Generic className Cleanup before adding logic:
		if (typeof this.iFrame.className === "string") {
			this.iFrame.className = this.iFrame.className
				.replace("coop-fullscreen-collapsed", "")
				.replace("coop-fullscreen", "");
		}
		if (this.mode === Surface.MODES.INLINE) {
		}
		if (this.mode === Surface.MODES.FULLSCREEN) {
			if (
				typeof this.iFrame.className === "string" &&
				this.iFrame.className.indexOf("coop-fullscreen") === -1
			) {
				this.iFrame.className = `${this.iFrame.className} coop-fullscreen`;
			}
		}
		if (this.mode === Surface.MODES.FULLSCREEN_COLLAPSED) {
			if (
				typeof this.iFrame.className === "string" &&
				this.iFrame.className.indexOf("coop-fullscreen-collapsed") ===
					-1
			) {
				this.iFrame.className = `${this.iFrame.className} coop-fullscreen-collapsed`;
			}
		}
	}

	handleModeChange() {
		this.message("UPDATE_MODE", {
			mode: this.mode,
		});
		this.setDims();
	}

	setMode(mode) {
		this.mode = mode;
		if (mode === Surface.MODES.FULLSCREEN) {
			this.config.lockBodyScroll(this.globalState);
		} else if (
			mode === Surface.MODES.INLINE ||
			mode === Surface.MODES.FULLSCREEN_COLLAPSED
		) {
			this.config.unlockBodyScroll(this.globalState);
		}

		this.handleModeChange();
	}

	prepareMount(...args) {
		super.prepareMount(...args);
		this.animateIn();
		this.setDims();
	}
}
export default Surface;
