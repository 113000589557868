export const WIDGET_TYPE = {
	DISCOFEED: "DISCOFEED",
	LEAD_GEN: "LEAD_GEN",
	ESSENTIAL: "ESSENTIAL",
	ENGAGE: "ENGAGE",
};

export const WIDGET_VERSION = {
	[WIDGET_TYPE.ESSENTIAL]: {
		0.1: 0.1,
		PRODUCTION: function () {
			return this["0.1"];
		},
	},
	[WIDGET_TYPE.ENGAGE]: {
		0.1: 0.1,
		PRODUCTION: function () {
			return this["0.1"];
		},
	},
	[WIDGET_TYPE.DISCOFEED]: {
		2.0: 2.0,
		2.1: 2.1,
		4.0: 4.0,
		PRODUCTION: function () {
			return this["2.1"];
		},
	},
	[WIDGET_TYPE.LEAD_GEN]: {
		0.1: 0.1,
		0.2: 0.2,
		0.3: 0.3,
		0.4: 0.4,
		0.5: 0.5,
		0.51: 0.51,
		0.55: 0.55,
		0.6: 0.6,
		0.65: 0.65,
		0.7: 0.7,
		0.75: 0.75,
		1: 1,
		PRODUCTION: function () {
			return this["0.55"];
		},
	},
};

export const EXPERIENCE_API_ROUTE_MAPPER = {
	[WIDGET_TYPE.DISCOFEED]: "core/discofeed",
	[WIDGET_TYPE.LEAD_GEN]: "lead_gen/generate_lead",
};
