import Footer from "../components/Footer";
import applyStyles from "../utils/applyStyles";
import {
	EXPERIENCE_API_ROUTE_MAPPER,
	WIDGET_TYPE,
	WIDGET_VERSION,
} from "../utils/configureExperience";
import getScriptMetadata from "../utils/getScriptMetadata";
import isMob from "../utils/isMob";
import Surface from "./Surface";

class Widget extends Surface {
	footer = false;
	isModal = false;
	modalOpen = null;

	manageFooter(payload) {
		if (!this.footer) return;
		if (payload) {
			this.footer.show();
		} else {
			this.footer.hide();
		}
	}
	initializeFooter(args) {
		if (!isMob()) return;
		this.footer = new Footer(args);
		this.footer.mount();
	}

	constructor({ config, messageCallbacks = {}, ...rest }) {
		const allMessageCallbacks = {
			...messageCallbacks,
			CLOSE_WIDGET_FULLSCREEN: () => {
				this.config.unlockBodyScroll(this.globalState);
				this.close();
			},
			CLOSE_MODAL: () => {
				this.closeModal();
			},
			SHOW_MODAL: () => {
				if (this.mode !== Widget.MODES.MODAL) {
					return;
				}
				if (isMob()) this.openModal();
				this.showModalButton();
			},
			RESIZE: ({ payload }) => {
				let stylePayload = { ...payload };
				if ("height" in stylePayload) {
					stylePayload = {
						...stylePayload,
						height: `${stylePayload.height}px`,
						minHeight: `${stylePayload.height}px`,
					};
				}
				applyStyles(this.iFrame, stylePayload);
			},
		};
		super({
			idx: "coop-widget",
			htmlId: "coopcommerce",
			config,
			src: config.makeUrl("widget"),
			mode: Widget.MODES.FULLSCREEN,
			hideInitially: config.getPublisherProperty("hideInitially") || true,
			messageCallbacks: allMessageCallbacks,
			...rest,
		});
		this.initializeFooter({
			config,
			widget: this,
			orchestrator: this.orchestrator,
			...rest,
		});
	}

	handleParentStats(payload) {
		const {
			widget: { isFrameInViewport },
		} = payload;
		this.manageFooter(isFrameInViewport);
	}

	unMount() {
		super.unMount();
		if (this.footer) {
			this.footer.unMount();
		}
		if (this.isModal) {
			this.unMountModal();
		}
	}
	close() {
		super.close();
		if (this.footer) {
			this.footer.unMount();
		}
	}

	openModal() {
		if (this.mode !== Widget.MODES.MODAL || this.modalOpen === true) return;
		this.modalOpen = true;
		this.modalOverlay.style.display = "block";
		document.body.style.overflow = "hidden";
		this.modalButton.innerHTML =
			"<img class='coop-modal-button-close' src='https://coopcommerce.s3-us-west-1.amazonaws.com/close.svg' alt='close' />";
		setTimeout(() => {
			this.iFrame.style.transform = "translateY(0px)";
			this.iFrame.style.opacity = "1";
			this.modalOverlay.style.opacity = "1";
		}, 1);

		if (this.orchestrator.surfaces.overlay.open) return;
		this.startTrack();
	}

	closeModal() {
		if (this.mode !== Widget.MODES.MODAL || this.modalOpen === false)
			return;
		this.iFrame.style.transform = "translateY(-50px)";
		this.iFrame.style.opacity = "0";
		this.modalOverlay.style.opacity = "0";
		document.body.style.overflow = "auto";
		this.modalButton.innerHTML = `<img src="https://coopcommerce.s3.us-west-1.amazonaws.com/static_assets/disco-logo-white.png" alt="Disco" />`;

		this.modalOpen = false;
		setTimeout(() => {
			this.modalOverlay.style.display = "none";
		}, 310);
	}

	showModalButton() {
		this.modalButton.style.display = "flex";
		setTimeout(() => {
			this.modalButton.style.transform = "scale(1) translateX(0px)";
			this.modalButton.style.opacity = "1";
		}, 0);
	}

	toggleModal() {
		if (this.modalOpen) {
			this.closeModal();
		} else {
			this.openModal();
		}
	}

	unMountModal() {
		if (!this.isModal) return;
		if (this.modalOverlay) {
			this.modalOverlay.remove();
		}
		if (this.modalButton) {
			this.modalButton.remove();
		}
	}

	prepareMountModal() {
		this.setMode(Surface.MODES.MODAL);
		this.isModal = true;
		if (this.footer) {
			this.footer.unMount();
		}
		const modalOverlay = document.createElement("section");
		modalOverlay.id = "coop-overlay";

		let reqStyles =
			this.config.getPublisherProperty("modalOverlayStyles") || {};
		applyStyles(modalOverlay, reqStyles);

		document.body.appendChild(modalOverlay);

		const modalButton = document.createElement("div");
		modalButton.id = "coop-modal-button";

		if (this.config.getPublisherProperty("leftShiftModalButton"))
			modalButton.classList.add("coop-modal-button-shifted-left");

		modalButton.innerHTML = `<img
            src="https://coopcommerce.s3.us-west-1.amazonaws.com/static_assets/disco-logo-white.png"
            alt="Disco"/>
        `;
		document.body.appendChild(modalButton);

		modalButton.addEventListener("click", this.toggleModal.bind(this));

		this.modalOverlay = modalOverlay;
		this.modalButton = modalButton;
		this.closeModal();

		this.iFrame.classList.add("coop-modal-frame");
	}

	configWidget({ type, version } = {}) {
		if (!this.iFrame || !type || !version) {
			return;
		}
		let additionalClassName = "";
		switch (type) {
			case WIDGET_TYPE.ESSENTIAL: {
				this.startTrack(); // To override the case if the Overlay is also open (we always track with the essential widget, since that's on top)
				additionalClassName = "coop-widget-type-essential";
				break;
			}
			case WIDGET_TYPE.LEAD_GEN: {
				break;
			}
			case WIDGET_TYPE.DISCOFEED: {
				switch (version) {
					case WIDGET_VERSION[WIDGET_TYPE.DISCOFEED][4.0]:
						additionalClassName = "coop-widget-4";
						break;
					default:
						additionalClassName = "";
				}
			}
		}
		this.iFrame.className = `${additionalClassName} ${this.iFrame.className}`;
	}

	prepareMount() {
		super.prepareMount(
			this.config.getPublisherProperty("disableModalMode")
		);
		if (!this.mountAfter) {
			if (this.config.getPublisherProperty("disableModalMode")) {
				console.log(
					"[ co-op ] Mount point missing and can't mount as modal."
				);
				this.unMount();
			} else this.prepareMountModal();
		}
	}

	getDefaultMountAfter() {
		let result = null;
		console.log("called DEFAULT getMountAfter");
		[
			".section",
			".orderConfirmation-section",
			".orderConfirmation",
		].forEach((selector) => {
			if (document.querySelector(selector)) {
				console.log("selector found:", selector);
				result = document.querySelector(selector);
			}
		});

		return result;
	}

	setMountAfter() {
		const getMountAfter = this.config.getPublisherProperty("getMountAfter");
		console.log("getMountAfter inner :>> ", getMountAfter);
		if (typeof getMountAfter === "function") {
			const mountAfter = getMountAfter();
			console.log("using special mountAfter :>> ", mountAfter);
			if (mountAfter) {
				this.mountAfter = mountAfter;
				return;
			}
		}
		this.mountAfter = this.getDefaultMountAfter();
		console.log("using mountAfter :>> ", this.mountAfter);
	}

	get loadMoreUrl() {
		return `${this.config.ACTIVE_ENV.apiUrl}core/discofeed/${this.config.PUBLISHER}/`;
	}

	get fetchUrl() {
		const generateUrl = ({ apiRouter = "core/discotheque" } = {}) =>
			`${this.config.ACTIVE_ENV.apiUrl}${apiRouter}/${this.config.PUBLISHER}/`;
		if (
			this.config.PREVIEW_EXPERIENCE &&
			this.config.PREVIEW_EXPERIENCE in EXPERIENCE_API_ROUTE_MAPPER
		) {
			return generateUrl({
				apiRouter:
					EXPERIENCE_API_ROUTE_MAPPER[this.config.PREVIEW_EXPERIENCE],
			});
		}
		return generateUrl({ apiRouter: "core/discotheque" });
	}

	startTrack() {
		if (this.isModal && !this.modalOpen) return;
		super.startTrack();
	}

	show(remoteData) {
		const isPPE = remoteData?.publisher_details?.post_purchase;
		const isPremium = remoteData?.publisher_details?.is_premium;

		const {
			activeWidgetType: widgetType,
			activeWidgetVersion: widgetVersion,
		} = this.globalState || {};

		this.configWidget({ type: widgetType, version: widgetVersion });

		const isMobile = isMob();

		let mode = Surface.MODES.MODAL;
		if (!this.isModal) {
			const isRemotelyConfigured =
				remoteData?.widget_mode in Surface.MODES;

			const shouldForceInline =
				widgetType === WIDGET_TYPE.LEAD_GEN || !isPPE || !isMobile;

			if (isRemotelyConfigured) {
				mode = Surface.MODES[remoteData.widget_mode];
			} else if (shouldForceInline) {
				mode = Surface.MODES.INLINE;
			} else {
				mode = Surface.MODES.FULLSCREEN;
			}
			this.setMode(mode);
		}

		if ((this.footer && isPremium) || true) {
			if (typeof this.footer.unMount === "function")
				this.footer.unMount();
		}

		// To re-send the start_track event to the newly added iFrame
		if (this.trackingStarted) {
			console.log("Remounted widget");
			this.startTrack();
		}

		this.message("LOAD", {
			...remoteData,
			global_event_metadata: {
				...(remoteData?.global_event_metadata || {}),
				...(this?.globalState?.globalEventMetadata || {}),
			},
			publisher: this.config.PUBLISHER,
			parentInnerHeight: window.innerHeight,
			parentInnerWidth: window.innerWidth,
			view: "WIDGET",
			initialMode: mode,
			scrapedInformation: this.orchestrator.getApiPayload(),
			coopSession: this.globalState.coopSession,
			loadMoreUrl: this.loadMoreUrl,
			runningExperiments: this.globalState.runningExperiments,
			scriptMetadata: getScriptMetadata({
				config: this.config,
				globalState: this.globalState,
			}),
		});
		this.setDims();
	}
	getApiRequest(payload) {
		return fetch(this.fetchUrl, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		});
	}
}

export default Widget;
