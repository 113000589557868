import { DEBUG } from "../config";

const log = (...log) => {
	if (!DEBUG) {
		return;
	}
	console.log("[CO-OP]:", ...log);
};

export default log;
