import uuid4 from "./utils/uuid4";
import probability from "./utils/probability";

const globalState = {
	originalBodyPos: "",
	hasLockedBodyPos: false,
	dataLoaded: false,
	user: {
		email: "",
		first_name: "",
		identifier: "",
	},
	coopSession: uuid4(),
	globalEventMetadata: {},

	activeWidgetType: null,
	activeWidgetVersion: null,

	shopTheme: {
		backgroundColor: null,
		foregroundColor: null,
	},
};

export default globalState;
